.footer {
  align-items: center;
  align-self: baseline;
  background-color: black;
  border-radius: 0 0 8px 8px;
  display: flex;
  gap: 28px;
  height: 100px;
  justify-content: flex-end;
  padding-right: 30px;
  width: 100%;
}
