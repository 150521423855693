@font-face {
  font-family: 'PPPangramSans';
  src: url('./fonts/PPPangramSans-Light.woff') format('woff');
  src: url('./fonts/PPPangramSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PPPangramSans';
  src: url('./fonts/PPPangramSans-Regular.woff') format('woff');
  src: url('./fonts/PPPangramSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PPPangramSans';
  src: url('./fonts/PPPangramSans-Medium.woff') format('woff');
  src: url('./fonts/PPPangramSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PPPangramSans';
  src: url('./fonts/PPPangramSans-Bold.woff') format('woff');
  src: url('./fonts/PPPangramSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PPPangramSans';
  src: url('./fonts/PPPangramSans-LightItalic.woff') format('woff');
  src: url('./fonts/PPPangramSans-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'PPPangramSans';
  src: url('./fonts/PPPangramSans-MediumItalic.woff') format('woff');
  src: url('./fonts/PPPangramSans-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'PPPangramSans';
  src: url('./fonts/PPPangramSans-BoldItalic.woff') format('woff');
  src: url('./fonts/PPPangramSans-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}