.people-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.people-container {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}
