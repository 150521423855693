.container {
  background-color: #131414;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 76px;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

@media (min-width: 1200px) {
  .container {
    /* DRAWER_WIDTH plus 22 */
    padding-left: 272px;
  }
}

.btn-container {
  column-gap: 15px;
  display: flex;
  height: 31px;
  justify-content: flex-end;
  margin-right: 50px;
}
